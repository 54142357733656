<template>
  <b-card-code title="جدول المواد الدراسية" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر البحث"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
              <b-button
                v-if="role"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة مادة جديد</span>
              </b-button>
              <export-excel style="color:white;padding-top:10px"
                  class   = "btn btn-default"
                  :data   = "items"
                  :fields = "exportFields"
                  worksheet = "My Worksheet"
                  name    = "filename.xls">
                  استخراج اكسل
              </export-excel>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col v-if="role" md="4">
          <b-form-group>
            <v-select
              v-if="role" 
              v-model="search.department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.stages.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="search.stage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.stages"
              placeholder="اختر المرحلة"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                v-b-modal.model-e-w
                :to="{ name: 'lecture-files', params: { id: action.item.id } }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </b-col>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                v-b-modal.model-e-w
                @click="eRM(action.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
              </b-button>
            </b-col>
            <b-col v-if="canDelete" v-show="action.item.is_deleted == 0" md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                v-b-modal.modal-center
                @click="dRM(action.item)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
              </b-button>
            </b-col>

            <b-col v-if="canDelete" v-show="action.item.is_deleted == 1" md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-info"
                @click="rDM(action.item.id)"
              >
                <feather-icon icon="AlertTriangleIcon" class="mr-50" />
              </b-button>
            </b-col> 
        </b-dropdown>
      </template>
      <template #cell(celbas)="celbas">
        <b-button v-if="celbas.item.celbas!==null"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(celbas.item.celbas)"
        >
          عرض المقرر
        </b-button>
        <b-button v-else
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-danger"
          disabled
        >
          لم يرفع المقرر 
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="تعديل المادة الدراسية"
      @show="checkModel"
      @ok="uM"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col v-if="role" md="12">
            <b-form-group>
              <label for="basic-password">اختر القسم</label>
              <v-select
                v-model="editform.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="canEdit" v-show="stages.length > 0">
            <b-form-group>
              <label for="basic-password">اختر المرحلة</label>
              <v-select
                v-model="editform.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="اختر المرحلة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="canEdit">
            <b-form-group>
              <label for="basic-password">اختر محاضر</label>
              <v-select
                v-model="editform.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="اختر محاضر"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">عنوان المادة باللغة العربية:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_name"
              :state="editform.ar_name.length > 0"
              placeholder="عنوان المادة باللغة العربية"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">عنوان المادة باللغة الانكليزية:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.en_name"
              :state="editform.en_name.length > 0"
              placeholder="عنوان المادة باللغة الانكليزية"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">ملف المقرر الدراسي:</label>
            <b-form-file
              placeholder="اختر او اسقط الملف (pdf.)..."
              drop-placeholder="اختر او اسقط الملف (pdf.)..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة مادة دراسية"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">اختر القسم</label>
              <v-select
                v-model="form.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="stages.length > 0">
            <b-form-group>
              <label for="basic-password">اختر المرحلة</label>
              <v-select
                v-model="form.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="اختر المرحلة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">اختر محاضر</label>
              <v-select
                v-model="form.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="اختر المحاضر"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">عنوان المادة باللغة العربية:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_name"
              :state="form.ar_name.length > 0"
              placeholder="عنوان المادة باللغة العربية"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">عنوان المادة باللغة الانكليزية:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.en_name"
              :state="form.en_name.length > 0"
              placeholder="عنوان المادة باللغة الانكليزية"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">ملف المقرر الدراسي:</label>
            <b-form-file
              placeholder="اختر او اسقط الملف (pdf.)..."
              drop-placeholder="اختر او اسقط الملف (pdf.)..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="Delete User"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        هل تريد تحذف هذه الحقل " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import { filesUrl } from "@/main.js";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,BProgress,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BRow,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormFile,
  BCardBody,
  BIconFileEarmarkSlides,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { SID,TID,AID } from "@/main.js";
import emailjs from '@emailjs/browser';
export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,BProgress,
  BFormFile,
    BBadge,
    BRow,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      role: null,
      canEdit:null,
      canDelete: null,
      currentPage: 0,
      department: "",
      title: "",
      iD:"",
      item: "",
      id: "",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      stages: [],
      search:{
        stages: [],
        departments: [],
        stage_id:"",
        department:"",
      },
      fields: [
        {
          key: "ar_name",
          label: "عنوان بالعربية",
        },
        {
          key: "en_name",
          label: "عنوان بالانكليزي",
        },
        {
          key: "stage.ar_name",
          label: "المرحلة الدراسية",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        {
          key: "celbas",
          label: "المقرر الدراسي",
        },
        { key: "files_count", label: "عدد المحاضرات " },
        { key: "teacher.ar_name", label: "محاضر المادة  " },
        { key: "action", label: "الاجراءات" },
      ],
      exportFields:{
          'عنوان المادة الدراسية': 'ar_name',
            'المرحلة الدراسية': 'stage.ar_name',
            'المقرر الدراسي': 'celbas',
            'القسم العلمي': 'department.ar_name',
            'عدد المحاضرات': 'files_count',
            'محاضر المادة': 'teacher.ar_name',
      },
      exportData:[
        {
          TotalName: "full_name",
          Stage: "stage.ar_name",
          Syllabus: "celbas",
          Department: "department.ar_name",
          Quntity: "files_count",
          Lecturer: "teacher.ar_name",
        },
      ],
      items: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        user_id:"",
        teacher_id:"",
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        en_name: "",
        ar_name: "",
        stage_id: "",
        department_id: "",
        teacher_id: "",
        file: "",
      },
      editform: {
        en_name: "",
        ar_name: "",
        stage_id: "",
        department_id: "",
        teacher_id: "",
        file: "",
        id: null,
      },
      selected: [],
      selected2: [],
      toEmail:false,
      toName:false,
      subject:null,
      toID:null,
      toUserID:null
    };
  },
  watch: {
    department: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    id: function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
    "editform.department_id": function (val) {
      if (val != null || val != "") {
        this.gSM1();
      }
    },
    "form.department_id": function (val) {
      if (val != null || val != "") {
        this.gSM3();
      }
    },
    "search.department": function (val) {
      if (val != null || val != "") {
        this.gSM4()
        this.gS()
      }
    },
    "search.stage_id": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (localStorage.getItem("NisourRole") == "admin") {
      this.role = true;
    } else {
      this.role = false;
    }
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "coordinator") {
      this.canEdit = true;
      if (localStorage.getItem("NisourRole") == "coordinator") {
      this.iD = localStorage.getItem("NisourDepartmentId");
    } 
    } else {
      this.canEdit = false;
    }
    
    if (localStorage.getItem("NisourRole") == "admin") {
      this.canDelete = true;
    } else {
      this.canDelete = false;
      this.search.department = localStorage.getItem("NisourDepartmentId");
    }
    this.ID(), this.gS(), this.gSM(), this.gSM2();
  },
  methods: {
    
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`
        )
        .then((res) => {
          this.departments = [];
          this.search.departments = res.data.items;
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      this.stages = [];
      this.editform.stage_id == "";
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.editform.department_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM3() {
      this.stages = [];
      this.form.stage_id == "";
      if (isNaN(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM2() {
      this.teachers = [];
      this.editform.teacher_id == "";
      await this.axios
        .get(`teachers-name?department_id=${this.iD}`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM4() {
      this.stages = [];
      this.search.stage_id == "";
      if (isNaN(this.search.department)) {
        this.search.department = this.search.department["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.search.department}?select=true`
        )
        .then((res) => {
          this.search.stage_id = "";
          this.search.stages = [];
          this.search.stages = res.data.items;
        })
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      if (isNaN(this.editform.stage_id)) {
        this.editform.stage_id = this.editform.stage_id["id"];
      }
      if (isNaN(this.editform.teacher_id)) {
        this.toEmail =this.editform.teacher_id['email']
        this.toName =this.editform.teacher_id['title']
        this.toID =this.editform.teacher_id['id']
        this.toUserID =this.editform.teacher_id['user_id']
        this.subject =this.editform.ar_name
        this.editform.teacher_id = this.editform.teacher_id["id"];
      }
      let data = new FormData();
      data.append("en_name", this.editform.en_name);
      data.append("ar_name", this.editform.ar_name);
      data.append("stage_id", this.editform.stage_id);
      data.append("department_id", this.editform.department_id);
      data.append("teacher_id", this.editform.teacher_id);
      data.append("celbas", this.editform.file);
      await this.axios
        .post(`lecture/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            if(this.toEmail&&this.toName){
              const m = `سيد ${this.toName} : نود اعلامك انه قد تم تحويل المادة الدراسية ${this.subject} يرجى رفع المحاضرات الدراسية في اقرب وقت ممكن لتكون متاحة للطلبة و في حال وجود مشكلة او خلل يرجى عمل رد للمسوؤل عن هذه التحويل`
              console.log(this.toEmail)
              console.log(this.toName)
              console.log(this.subject)
              this.sendEmail(m,this.toName,this.toEmail)
              }
      
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.notifi.route = "lectures-notif";
            this.notifi.title = "update action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id = 1;
            this.notifi.type = "light-worning";
            this.notifi.subtitle = "updated a lecture";
            this.notifi.bg = "bg-worning";
            this.sendNoti();
            this.notifi.route = "lectures-notif";
            this.notifi.title = "تحويل مادة دراسية ";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id =null;
            this.notifi.teacher_id = this.toID;
            this.notifi.user_id = this.toUserID;
            this.notifi.type = "light-worning";
            this.notifi.subtitle = `نود اعلامك بتحويل المادة الدراسية ${this.subject} اليك `;
            this.notifi.bg = "bg-worning";
            this.sendNoti();
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    sendEmail(m,n,e) {
      console.log(e)
      const fromName = localStorage.getItem("NisourUserName")
      const fromEmail = localStorage.getItem("NisourEmail")
      emailjs.send(SID,TID,{
          from_name: fromName,
         to_name: n, 
          message: m,
          to_email: e,
          reply_to:fromEmail
        },AID)
        .then((result) => {
            this.makeToast(
              "success",
              "Notification Sender",
              "The notification has been sent successfully "
            );
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
    async aNM() {
      if (isNaN(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      if (isNaN(this.form.stage_id)) {
        this.form.stage_id = this.form.stage_id["id"];
      }
      if (isNaN(this.form.teacher_id)) {
        this.form.teacher_id = this.form.teacher_id["id"];
      }
       let data = new FormData();
      data.append("en_name", this.form.en_name);
      data.append("ar_name", this.form.ar_name);
      data.append("stage_id", this.form.stage_id);
      data.append("department_id", this.form.department_id);
      data.append("teacher_id", this.form.teacher_id);
      data.append("celbas", this.form.file);
      await this.axios
        .post(`lecture`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
            this.notifi.route = "lectures-notif";
            this.notifi.title = "add action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id = 1;
            this.notifi.type = "light-Success";
            this.notifi.subtitle = "added a new lecture";
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    rF() {
      (this.form.en_name = ""),
        (this.form.ar_name = ""),
        (this.form.stage_id = ""),
        (this.form.teacher_id = ""),
        (this.form.department_id = "");
    },
    rEF() {
      (this.editform.en_name = ""),
        (this.editform.ar_name = ""),
        (this.editform.stage_id = ""),
        (this.editform.department_id = ""),
        (this.editform.teacher_id = ""),
        (this.editform.id = "");
    },
    async dM() {
      await this.axios
        .delete(`lecture/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
            this.notifi.route = "lectures-notif";
            this.notifi.title = "delete action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id = 1;
            this.notifi.type = "light-danger";
            this.notifi.subtitle = "deleted lecture";
            this.notifi.bg = "bg-danger";
            this.sendNoti();
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async rDM(id) {
      await this.axios
        .get(`lecture/recycle/${id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item);
      this.editform.en_name = item.en_name;
      this.editform.ar_name = item.ar_name;
      this.editform.stage_id = item.stage_id;
      this.editform.department_id = item.department_id;
      this.editform.teacher_id = item.teacher_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if (isNaN(this.search.department) & (this.search.department != "")) {
        this.search.department = this.search.department["id"];
      }
      if (isNaN(this.search.stage_id) & (this.search.stage_id != "")) {
        this.search.stage_id = this.search.stage_id["id"];
      }
      await this.axios
        .get(
          `lecture?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&department=${this.search.department}&stage_id=${this.search.stage_id}&title=${this.title}&teacher_id=${
            this.teacher_id
          }&show=${this.$route.params.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
          
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(
          `lecture?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&teacher_id=${this.teacher_id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    selectFileForForm(e) {
      this.form.file = "";
      this.form.file = e.target.files[0];
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      this.editform.file = e.target.files[0];
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.search.department = ""),(this.search.stage_id = "");
      this.gS();
    },
    onClicked(path) {
      console.log(path)
      window.open(`${filesUrl}/${path}`, "_blank");
    },
  },
};
</script>
